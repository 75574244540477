import restaurant from "./images/panzelealbe.jpg";
import biserica from "./images/bis.jpg";
import img_card from "./images/vasile.jpg";
import imgOmSuflet from "./images/family.jpg";
import imgOmSufletmb from "./images/family.jpg";
import imgheader from "./images/bu2.jpeg";
import img2 from "./images/vasile.jpg";
import imgheadermiini from "./images/fr2.jpg";
import imgheadermiinimb from "./images/fr2.jpg";
import imgconfirmare from "./images/fonrose4.jpeg";



const data = {
    introData: [{
        familia: "Vasile & Vera",
        mire: "Vera",
        mireasa: "Vasile",
        copil: '',
        data: "3 August 2024",
        data_confirmare: "15 iulie 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "vera.lupu999@gmail.com", 
       tel: "+373 692 61 789 ",
       phone: "tel:+37369261789",
       viber: "viber://chat?number=%2B37369261789",
       whatsapp: "https://wa.me/+37369261789",
       messenger: "https://www.messenger.com/t/vera.lupu.52",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/xxx",
    }],
    cardData: [
       
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentelor",
            localul: "Restaurantul",
            name: "Pânzele Albe",
            data: "3 august 2024, sâmbătă, ora 17:00",
            ora: "",
            adress: "malul drept al lacului Ghidighici, Chişinău",
            harta: "https://maps.app.goo.gl/w1i77KzqkLvbyZ3p8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10863.564255931211!2d28.7046541!3d47.1012062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cbd73366a38021%3A0xafbde61d3ca25362!2sP%C3%A2nzele%20Albe!5e0!3m2!1sro!2s!4v1699626618055!5m2!1sro!2s" 
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "Cununie și botez!",
            mami: "",
            tati: "La 2 în 1 vă poftim!",
            message: "Foaie verde de trifoi,     ",
            message1: "Super promoție la noi:",
            message2: "Chiar așa! Ce, nu ne crezi?",
            message3: "Hai să vezi că nu glumim.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Vă invităm la celebrarea căsătoriei noastre!",
            message: "Pentru că cele mai frumoase momente se sărbătoresc alături de cei dragi, vrem să fim înconjurați de cei mai apropiați și scumpi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            message1: "P.S. Iubim florile,  dar cu toată dragostea dorim să facem un act de caritate, \n deci vă rugăm ca în loc de flori să aducem în dar cadouri pentru copii cum ar fi: cărți, jucării, creioane colorate, jocuri interactive, hăinuțe ș.a. care vor fi donate pentru copii din centre de plasament!  \n  Mulțumim de înțelegere și haideți să aducem bucuria și în sufletele copiilor!",
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Serghei și Ala ",
            parintii_nume2: "Maria",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Florin şi Ana-Maria",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: `De aici începe totul.`,
            message: "\"Dragostea înseamnă un singur suflet care sălăsluiește în două corpuri.\"– Aristotel",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;